import moment from "moment";
import React from "react";
import { useMutation } from "react-apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import {
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  OperatingUsers,
  Operator,
  Supervisor,
} from "../../assets/scss/role.constant";
import { UPDATE_ONE_USER } from "../../graphql/mutation";
import { CLEAR_PHONE_NO } from "../../graphql/mutation/CLEAR_PHONE_NO";
import { MARK_PHONE_AS_VERIFIED } from "../../graphql/mutation/MARK_PHONE_AS_VERIFIED";
import ToastHelper from "../../helpers/ToastHelper";
import WalletHelper from "../../helpers/WalletHelper";
import PlayerDownlines from "../Workbench/Banker/player-downlines";

function PlayerTableItem(props) {
  const [isActive, setIsActive] = React.useState(false);
  const [drp_link, setDrpLink] = React.useState(false);

  const [confirmReset, setConfirmReset] = React.useState(false);
  const [openDownlines, setOpenDownlines] = React.useState(false);

  const [clearPhoneNo] = useMutation(CLEAR_PHONE_NO);

  const {
    user,
    onEdit,
    onResetPassword,
    onManageTags,
    onManageInWorkbench,
    onManageWallet,
    meData,
    onChangeUpline,
  } = props;

  const [updateOneUser] = useMutation(UPDATE_ONE_USER);
  const [markPhoneAsVerified] = useMutation(MARK_PHONE_AS_VERIFIED);

  const dateToFromNowDaily = (myDate) => {
    var fromNow = moment(myDate).fromNow();

    return moment(myDate).calendar(null, {
      lastWeek: "[Last] dddd",
      lastDay: "[Yesterday]",
      sameDay: "[Today] HH:mm",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {props.text}
      </div>
    );
  };

  const ResetPhoneVerification = () => {
    setConfirmReset(false);
    clearPhoneNo({
      variables: {
        userId: user.node.id,
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () {},
        });
      })
      .then((val) => {
        if (val) {
          ToastHelper.toggleToast({
            message: "Reset Succesfully!",
            title: "",
            onClick: function () {},
          });
        }
      });
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {props.text}
      </div>
    );
  };

  const onMarkPhoneAsVerified = () => {
    markPhoneAsVerified({
      variables: {
        userId: user.node.id,
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () {},
        });
      })
      .then((val) => {
        if (val) {
          ToastHelper.toggleToast({
            message: "Update Succesfully!",
            title: "",
            onClick: function () {},
          });
        }
      });
  };

  React.useEffect(() => {
    if (user) {
      setIsActive(user.node.isActive);
    }
  }, [user]);

  return (
    <React.Fragment>
      <tr>
        <td>
          <div className="cursor-pointer " onClick={onEdit}>
            {user.node.serialNo}
          </div>
        </td>
        <td>
          <>
            <div className="d-flex">
              <div className="cursor-pointer" onClick={onManageInWorkbench}>
                <i className="cursor-pointer bx bxs-wrench font-size-20"></i>
              </div>
              <div className="mr-1"></div>
              <div className="cursor-pointer" onClick={onEdit}>
                {user.node.username}
              </div>
              {/* <div className="ml-1">(</div>
                        <div className="cursor-pointer" onClick={onEdit}>{user.node.wallets.edges.length > 0 ? parseFloat(user.node.wallets.edges[0].node.balance).toFixed(2) : '0.00'}</div>
                        <div className="mr-1"></div>
                        <div className="cursor-pointer" onClick={() => onManageWallet(user.node.wallets.edges[0].node)}>
                            <i className="bx bx-wallet font-size-20"></i>
                        </div>
                        <div>)</div> */}
            </div>
          </>
          <>
            <div className="d-flex">
              <div className="cursor-pointer" onClick={onChangeUpline}>
                <i className="cursor-pointer bx bx-male font-size-20"></i>
              </div>
              <div className="mr-1"></div>
              {user.node.upline && (
                <div className="cursor-pointer" onClick={onChangeUpline}>
                  {user.node.upline.serialNo}
                </div>
              )}
            </div>
          </>
        </td>
        <td>
          {WalletHelper.getMainWallet(user.node.wallets.edges) && (
            <>
              <div className="d-flex align-center">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    onManageWallet(
                      WalletHelper.getMainWallet(user.node.wallets.edges).node
                    )
                  }
                >
                  <i className="bx bx-wallet font-size-15"></i>
                </div>
                <div className="ml-1">{`$${WalletHelper.getBalance(
                  WalletHelper.getMainWallet(user.node.wallets.edges).node
                )}`}</div>
              </div>
            </>
          )}
          {WalletHelper.getRewardWallet(user.node.wallets.edges) && (
            // WalletHelper.getRewardWallet(user.node.wallets.edges).node.balance > 0 &&
            <>
              <div className="d-flex pt-1 align-center">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    onManageWallet(
                      WalletHelper.getRewardWallet(user.node.wallets.edges).node
                    )
                  }
                >
                  <i className="bx bx-gift font-size-15"></i>
                </div>
                <div className="mr-1 ml-1">{`RP${
                  WalletHelper.getRewardWallet(user.node.wallets.edges).node
                    .balance
                }`}</div>
                {moment(
                  WalletHelper.getRewardWallet(user.node.wallets.edges).node
                    .expiredAt
                ).isSameOrAfter(moment()) && (
                  <>
                    <i
                      className="bx bxs-info-circle text-danger font-size-16"
                      id={`expired-${
                        WalletHelper.getRewardWallet(user.node.wallets.edges)
                          .node.id
                      }`}
                    />
                    <UncontrolledTooltip
                      placement="right"
                      target={`expired-${
                        WalletHelper.getRewardWallet(user.node.wallets.edges)
                          .node.id
                      }`}
                    >
                      {`RP Expire In ${moment(
                        WalletHelper.getRewardWallet(user.node.wallets.edges)
                          .node.expiredAt
                      ).diff(moment(), "days")} ${
                        moment(
                          WalletHelper.getRewardWallet(user.node.wallets.edges)
                            .node.expiredAt
                        ).diff(moment(), "days") > 1
                          ? "days"
                          : "day"
                      }`}
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </>
          )}
          {WalletHelper.getAgentWallet(user.node.wallets.edges) && (
            // WalletHelper.getRewardWallet(user.node.wallets.edges).node.balance > 0 &&
            <>
              <div className="d-flex pt-1 align-center">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    onManageWallet(
                      WalletHelper.getAgentWallet(user.node.wallets.edges).node
                    )
                  }
                >
                  <i className="bx bx-network-chart font-size-15"></i>
                </div>
                <div className="mr-1 ml-1">{`AP${WalletHelper.getBalance(
                  WalletHelper.getAgentWallet(user.node.wallets.edges).node
                )}`}</div>
                {moment(
                  WalletHelper.getAgentWallet(user.node.wallets.edges).node
                    .expiredAt
                ).isSameOrAfter(moment()) && (
                  <>
                    <i
                      className="bx bxs-info-circle text-danger font-size-16"
                      id={`expired-${
                        WalletHelper.getAgentWallet(user.node.wallets.edges)
                          .node.id
                      }`}
                    />
                    <UncontrolledTooltip
                      placement="right"
                      target={`expired-${
                        WalletHelper.getAgentWallet(user.node.wallets.edges)
                          .node.id
                      }`}
                    >
                      {`AP Expire In ${moment(
                        WalletHelper.getAgentWallet(user.node.wallets.edges)
                          .node.expiredAt
                      ).diff(moment(), "days")} ${
                        moment(
                          WalletHelper.getAgentWallet(user.node.wallets.edges)
                            .node.expiredAt
                        ).diff(moment(), "days") > 1
                          ? "days"
                          : "day"
                      }`}
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </>
          )}
        </td>
        <td>
          <div className="cursor-pointer " onClick={onEdit}>
            {user.node.name}
          </div>
        </td>
        {meData &&
          !OperatingUsers.includes(meData.me.role) &&
          !Operator.includes(meData.me.role) &&
          !Supervisor.includes(meData.me.role) && (
            <td>
              <div>
                <div className="align-center">
                  <div className="cursor-pointer" onClick={onEdit}>
                    {user.node.phoneNo ? user.node.phoneNo : "-"}
                  </div>
                  {user.node.phoneNo && (
                    <Link
                      target="_blank"
                      to={{ pathname: `https://wa.me/${user.node.phoneNo}` }}
                      className="ml-2"
                    >
                      <i className="bx bxl-whatsapp whatsapp font-size-20 cursor-pointer" />
                    </Link>
                  )}
                </div>
                {user.node.phoneNoVerifiedAt && (
                  <div className="align-center">
                    <span className="mr-1 text-success">✔</span>
                    {dateToFromNowDaily(user.node.phoneNoVerifiedAt)}
                  </div>
                )}
              </div>
            </td>
          )}
        <td>
          <div className="d-flex">
            <div
              className="cursor-pointer"
              onClick={() => setOpenDownlines(true)}
            >
              <i className="cursor-pointer bx bx-sitemap font-size-20"></i>
            </div>
            <div className="mr-1"></div>
            <div
              className="cursor-pointer"
              onClick={() => setOpenDownlines(true)}
            >
              {`(${user.node.downlines.totalCount})`}
            </div>
          </div>
        </td>
        <td>{moment(user.node.joinedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
        {meData &&
          !OperatingUsers.includes(meData.me.role) &&
          !Operator.includes(meData.me.role) && (
            <td>
              <Switch
                uncheckedIcon={<Offsymbol text="NO" />}
                checkedIcon={<OnSymbol text="YES" />}
                id="is-active"
                onColor="#34c38f"
                offColor="#f46a6a"
                onChange={() => {
                  updateOneUser({
                    variables: {
                      input: {
                        update: {
                          isActive: !isActive,
                        },
                        id: user.node.id,
                      },
                    },
                  })
                    .catch((e) => {
                      ToastHelper.toggleToast({
                        toastType: "error",
                        message: e,
                        title: "",
                        onClick: function () {},
                      });
                    })
                    .then((val) => {
                      if (val) {
                        ToastHelper.toggleToast({
                          message: "Update Succesfully!",
                          title: "",
                          onClick: function () {},
                        });
                      }
                    });
                  setIsActive(!isActive);
                }}
                checked={isActive}
              />
            </td>
          )}
        <td>
          <Switch
            uncheckedIcon={<Offsymbol text="NO" />}
            checkedIcon={<OnSymbol text="YES" />}
            id="is-active"
            onColor="#34c38f"
            offColor="#f46a6a"
            onChange={() => {
              updateOneUser({
                variables: {
                  input: {
                    update: {
                      enableRewardPoint: !user.node.enableRewardPoint,
                    },
                    id: user.node.id,
                  },
                },
              })
                .catch((e) => {
                  ToastHelper.toggleToast({
                    toastType: "error",
                    message: e,
                    title: "",
                    onClick: function () {},
                  });
                })
                .then((val) => {
                  if (val) {
                    ToastHelper.toggleToast({
                      message: "Update Succesfully!",
                      title: "",
                      onClick: function () {},
                    });
                  }
                });
            }}
            checked={user.node.enableRewardPoint}
          />
        </td>
        {meData &&
          !OperatingUsers.includes(meData.me.role) &&
          !Operator.includes(meData.me.role) && (
            <td>
              <div>
                <div className="align-center">
                  <div className="cursor-pointer" onClick={onEdit}>
                    {user.node.telegramId ? user.node.telegramId : "-"}
                  </div>
                  {user.node.telegramUsername && (
                    <Link
                      target="_blank"
                      to={{
                        pathname: `https://t.me/${user.node.telegramUsername}`,
                      }}
                      className="ml-2"
                    >
                      <i className="bx bxl-telegram font-size-20 cursor-pointer" />
                    </Link>
                  )}
                </div>
                {user.node.telegramUsername && (
                  <div className="align-center">
                    <span className="mr-1 text-primary">
                      <i className="bx bx-user font-size-20" />
                    </span>
                    {user.node.telegramUsername}
                  </div>
                )}
              </div>
            </td>
          )}
        <td>
          <div
            className="d-flex flex-direction-row cursor-pointer flex-wrap"
            onClick={onManageTags}
          >
            {user.node.tags.edges.map((tag, index) => {
              return (
                <div
                  key={"_tag_" + index}
                  className="p-1 m-1 badge badge-primary"
                >
                  {tag.node.name}
                </div>
              );
            })}
            {user.node.tags.edges.length === 0 && <div>No Tag Found</div>}
          </div>
        </td>
        {meData &&
          !OperatingUsers.includes(meData.me.role) &&
          !Operator.includes(meData.me.role) && (
            <td>
              <Col className="dropdown-actions-table">
                <ButtonDropdown
                  isOpen={drp_link}
                  toggle={() => setDrpLink(!drp_link)}
                >
                  <DropdownToggle caret color="secondary">
                    Actions <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {/* <DropdownItem onClick={onEdit}>Edit User</DropdownItem> */}
                    <DropdownItem onClick={onResetPassword}>
                      Change Password
                    </DropdownItem>
                    <DropdownItem onClick={onMarkPhoneAsVerified}>
                      Mark Phone As Verified
                    </DropdownItem>
                    {/* <DropdownItem>Purge Session</DropdownItem> */}
                    {/* <DropdownItem onClick={onManageUser}>Manage User</DropdownItem> */}
                    {/* <DropdownItem onClick={onManageTags}>Manage Tags</DropdownItem> */}
                    <DropdownItem onClick={() => setConfirmReset(true)}>
                      Reset Phone Verification{" "}
                    </DropdownItem>
                    <DropdownItem onClick={onManageInWorkbench}>
                      Manage In Workbench
                    </DropdownItem>
                    {!OperatingUsers.includes(meData.me.role) &&
                      !Operator.includes(meData.me.role) && (
                        <DropdownItem onClick={onChangeUpline}>
                          Change UpLine{" "}
                        </DropdownItem>
                      )}
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
            </td>
          )}
      </tr>
      {confirmReset && (
        <SweetAlert
          error
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          title={"Alert"}
          onConfirm={ResetPhoneVerification}
          onCancel={() => setConfirmReset(false)}
        >
          {`This will clear phone no. and reset phone no. verification process, are you sure?`}
        </SweetAlert>
      )}
      {openDownlines && (
        <Modal
          isOpen={openDownlines}
          role="dialog"
          size="lg"
          autoFocus={true}
          centered={true}
          id="verificationModal"
          tabIndex="-1"
          toggle={() => {
            setOpenDownlines(!openDownlines);
          }}
        >
          <div className="modal-content edit-user-page">
            <ModalHeader toggle={() => setOpenDownlines(!openDownlines)}>
              Downlines
            </ModalHeader>
            <ModalBody>
              <PlayerDownlines player={user.node} />
            </ModalBody>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default PlayerTableItem;
