import moment from 'moment';
import React from 'react';
import { useMutation } from 'react-apollo';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from "react-router-dom";
import { ButtonDropdown, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import Avatar from '../../components/Common/Avatar';
import { CLEAR_PHONE_NO } from '../../graphql/mutation/CLEAR_PHONE_NO';
import ToastHelper from '../../helpers/ToastHelper';

function PlayerItem(props) {

    const { user, onEdit, onResetPassword, onManageTags, onManageInWorkbench, onManageWallet } = props;

    const [drp_link, setDrpLink] = React.useState(false)
    const [confirmReset, setConfirmReset] = React.useState(false)

    const [clearPhoneNo] = useMutation(CLEAR_PHONE_NO)

    const dateToFromNowDaily = (myDate) => {
        var fromNow = moment(myDate).fromNow();

        return moment(myDate).calendar(null, {
            lastWeek: '[Last] dddd',
            lastDay: '[Yesterday]',
            sameDay: '[Today] hh:mm',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            sameElse: function () {
                return "[" + fromNow + "]";
            }
        });
    }

    const ResetPhoneVerification = () => {
        setConfirmReset(false)
        clearPhoneNo({
            variables: {
                userId: user.node.id
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Reset Succesfully!",
                    title: "",
                    onClick: function () { }
                })
            }
        })
    }

    return (
        <React.Fragment>
            <Col xl="3" sm="6" lg="4">
                <Card>
                    <CardBody style={{ padding: '15px 1.25rem' }}>
                        <Row>
                            <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={onEdit}>
                                <Avatar name={user.node.name} style={{ paddingRight: 15 }} />
                                <div>
                                    <div className="header">Serial No</div>
                                    <div className="font-size-14"><div className="text-dark">{user.node.serialNo}</div></div>
                                </div>
                            </Col>
                            <Col xl="12" sm="12" className="padding-bottom align-center" >
                                <div>
                                    <div className="header">Name (Username)</div>
                                    <div className="font-size-14">
                                        <div className="text-dark d-flex  cursor-pointer" onClick={onEdit}>{user.node.name}
                                            <span className="text-username  cursor-pointer" onClick={onEdit}>({user.node.username})</span>
                                            <div className="cursor-pointer" onClick={onManageInWorkbench}>
                                                <i className="bx bxs-wrench font-size-20 ml-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="12" sm="12" className="padding-bottom align-center" >
                                <div>
                                    <div className="header">Wallet</div>
                                    <div className="font-size-14">
                                        <div className="text-dark d-flex  cursor-pointer" onClick={onEdit}>{user.node.wallets.edges[0].node.balance}
                                            <div className="cursor-pointer" onClick={() => onManageWallet(user.node.wallets.edges[0].node)}>
                                                <i className="bx bx-wallet font-size-20 ml-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="12" sm="12" className="padding-bottom align-center cursor-pointer " onClick={onEdit}>
                                <div>
                                    <div className="header">Mobile Number</div>
                                    <div className="font-size-14">
                                        <div className="align-center">
                                            <a className="cursor-pointer " onClick={onEdit}>
                                                {user.node.phoneNo ? user.node.phoneNo : '-'}
                                            </a>
                                            {
                                                user.node.phoneNo &&
                                                <Link target="_blank" to={{ pathname: `https://wa.me/${user.node.phoneNo}` }} className="ml-2" >
                                                    <i className="bx bxl-whatsapp whatsapp font-size-20 cursor-pointer" />
                                                </Link>
                                            }
                                        </div>
                                        {
                                            user.node.phoneNoVerifiedAt &&
                                            <div className="align-center">
                                                <span className="mr-1 text-success">✔</span>{dateToFromNowDaily(user.node.phoneNoVerifiedAt)}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col xl="12" sm="12" className="padding-bottom align-center ">
                                <div>
                                    <div className="header">Joined At</div>
                                    <div className="font-size-14"><Link to="#" className="text-dark">{moment(user.node.joinedAt).format('YYYY-MM-DD HH:mm:ss')}</Link></div>
                                </div>
                            </Col>
                            <Col xl="12" sm="12" className="padding-bottom align-center">
                                <div>
                                    <div className="header">Status</div>
                                    <div className="font-size-14"><Link to="#" className="text-dark">{user.node.isActive ? 'Active' : 'Inactive'}</Link></div>
                                </div>
                            </Col>

                            <Col xl="12" sm="12" className="padding-bottom align-center">
                                <div>
                                    <div className="header">Telegram ID</div>
                                    <div className="font-size-14"><Link to="#" className="text-dark">{user.node.telegramId ? user.node.telegramId : "-"}</Link></div>
                                </div>
                            </Col>

                            <Col xl="12" sm="12" className="padding-bottom align-center cursor-pointer " onClick={onManageTags}>
                                <div>
                                    <div className="header">Tags</div>
                                    <div className="d-flex flex-direction-row flex-wrap">
                                        {
                                            user.node.tags.edges.map((tag, index) => {
                                                return <div key={"_tag_" + index} className="p-1 m-1 badge badge-primary">
                                                    {tag.node.name}
                                                </div>
                                            })
                                        }
                                        {
                                            user.node.tags.edges.length === 0 && <div>
                                                No Tag Found
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col xl="12" sm="12" className="padding-bottom dropdown-actions">
                                <ButtonDropdown
                                    isOpen={drp_link}
                                    toggle={() =>
                                        setDrpLink(!drp_link)
                                    }
                                >
                                    <DropdownToggle caret color="secondary">
                                        Actions <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {/* <DropdownItem onClick={onEdit}>Edit User</DropdownItem> */}
                                        <DropdownItem onClick={onResetPassword}>Change Password</DropdownItem>
                                        {/* <DropdownItem>Purge Session</DropdownItem> */}
                                        {/* <DropdownItem onClick={onManageUser}>Manage User</DropdownItem> */}
                                        {/* <DropdownItem onClick={onManageTags}>Manage Tags</DropdownItem> */}
                                        <DropdownItem onClick={() => setConfirmReset(true)}>Reset Phone Verification </DropdownItem>
                                        <DropdownItem onClick={onManageInWorkbench}>Manage In Workbench </DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            {
                confirmReset &&
                <SweetAlert
                    error
                    showCancel
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="primary"
                    title={'Alert'}
                    onConfirm={ResetPhoneVerification}
                    onCancel={() => setConfirmReset(false)}
                >
                    {`This will clear phone no. and reset phone no. verification process, are you sure?`}
                </SweetAlert>
            }
        </React.Fragment>
    );
}

export default PlayerItem;